<template>
<div class="ticket">
    <center>
        <img :src="'/logo/logo_brayan4.png'" alt="Logo" style="width:50px;height:50px;margin-bottom:15px">
    </center>
    <p class="centered">STRUK PEMBAYARAN HUTANG
        <br>
        {{ print_value.invoice }}
        <br>
        Status: {{ ((cekloghutang() + cekloghutangSudah()) -cektotal()) == 0 ? 'Lunas' : 'Hutang' }}
    </p>

    -------------------------------------------------

    <table style="border:0px;margin-top:0px">
        <tbody style="border:0px">
            <tr style="border:0px" v-if="print_value">
                <!-- <td class="quantity"></td> -->
                <td style="border:0px" class="description">TOTAL</td>
                <!-- <td class="price"></td> -->
                <td style="border:0px" class="price1"> {{ cektotal().toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.") }}</td>
            </tr>

        </tbody>
    </table>
    <table style="border:0px;margin-top:0px">
        <tbody style="border:0px">
            <tr style="border:0px" v-if="print_value">
                <!-- <td class="quantity"></td> -->
                
                <td  style="border:0px" class="description">SUDAH BAYAR </td>
                 
              
                <td style="border:0px" class="price1"> {{ cekloghutangSudah().toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.") }}</td>
                 
            </tr>
            <tr>
                <td  style="border:0px" class="description">BARU {{parseInt(this.$route.query.data[1] )+1}}</td>
                 <td style="border:0px" class="price1"> {{ cekloghutang().toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.") }}</td>
            </tr>

        </tbody>
    </table>
    <table style="border:0px;margin-top:0px">
        <tbody style="border:0px">
            <tr style="border:0px" v-if="print_value">
                <!-- <td class="quantity"></td> -->
             
                <td  style="border:0px" class="description">SISA</td>
                <!-- <td class="price"></td> -->
               
                <td  style="border:0px" class="price1"> {{ ((cekloghutang() + cekloghutangSudah()) -cektotal()).toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.") }}</td>
            </tr>

        </tbody>
    </table>

    <br>
    <p class="centered">{{ filterdate(this.print_value.log_hutang[parseInt(this.$route.query.data[1])].created_at) }}<br>Thanks for your purchase!
        <br>Brayan Diesel </p>

    <button id="btnPrint" class="hidden-print btn btn-primary text-white" @click="printed">Print</button>
</div>
</template>

<script>
import axios from 'axios'
import moment from 'moment';
export default {
    data() {
        return {
            //state loggedIn with localStorage
            loggedIn: localStorage.getItem('loggedIn'),
            //state token
            token: localStorage.getItem('token'),
            //state user logged In
            print_value: [],
            jumtransaksi: 0,
            jumpendapatan: 0,
            link: this.globalApiUrl,
        }
    },
    created() {
        if (!this.loggedIn) {
            if (!this.token) {
                localStorage.removeItem("loggedIn");
                localStorage.removeItem("token");
                return this.$router.push({
                    name: 'login.index'
                })
            } else {
                localStorage.removeItem("loggedIn");
                localStorage.removeItem("token");
            }
        } else {
            console.log(this.token);
            let data = this.$route.query.data[0];
            axios.get(this.link + 'api/transaksi-print?inv=' + data, {
                headers: {
                    'Authorization': this.token
                }
            }).then(response => {
                console.log(response);
                this.print_value = response.data.data;
                console.log(this.print_value);
                // assign response to state user
            }).catch(error => {
                window.opener = self;
                window.close();
                setTimeout(function () {
                    window.close();
                }, 800);
            });

        }

    },
    mounted() {
        // print();
    },
    methods: {
        printed() {
            print();
        },
        filterdate(a) {
            // console.log(a);
            return moment(String(a)).format('h:mm:ss DD/MM/YYYY')
        },
        ceksubtotal(index) {
            var harga_fix = 0;
            harga_fix = harga_fix + (this.print_value.detail_transaksi[index].harga_fix * this.print_value.detail_transaksi[index].qty)
            return harga_fix;
        },
        cektotal() {
            var harga_fix = 0;
                console.log(this.print_value.detail_transaksi);
                this.print_value.detail_transaksi.forEach(i => {
    
                    harga_fix = harga_fix + (i.harga_fix * i.qty* ((100-i.diskon)/100))
                });
                return harga_fix-this.print_value.potongan;
        },
        cekloghutang() {

            return this.print_value.log_hutang[parseInt(this.$route.query.data[1])].bayar;
        },
        cekloghutangSudah() {
            var harga_fix = 0;
            var c = 0;
            this.print_value.log_hutang.forEach(i => {
                if (c <= (parseInt(this.$route.query.data[1])-1)) {
                    harga_fix = harga_fix + (i.bayar)
                }
                c = c + 1;
            });
            return harga_fix;
        }
    },
}
</script>

<style>
* {
    font-size: 10px !important;
    font-family: 'arial' !important;
    line-height: normal !important;
    /* font-weight:normal; */
}

body {
    color: #000000 !important;
    padding-top: 10px !important;

}

td,
th,
tr,
table {
    border-top: 1px solid black;
    border-collapse: collapse;
}

td.description,
th.description {
    width: 95px;
    max-width: 95px;
}

td.quantity,
th.quantity {
    width: 26px;
    max-width: 26px;
    font-size: 9.3px !important;
    word-break: break-all;
}

td.price1,
th.price1 {
    width: 75px;
    max-width: 75px;
    font-size: 9.3px !important;
    word-break: break-all;
}

td.price,
th.price {
    width: 75px;
    max-width: 75px;
    font-size: 9.3px !important;
    word-break: break-all;
}

.centered {
    text-align: center;
    align-content: center;
}

.ticket {
    /* margin-left: 4px; */
    width: 170px;
    max-width: 170px;

}

img {
    max-width: inherit;
    width: inherit;
}

@media print {

    .hidden-print,
    .hidden-print * {
        display: none !important;
    }
}
</style>
